"use strict";

import $ from 'jquery';

export function initInScope ($scope = $('body')) {
    let $sliders = $scope.find('.js-img-slider');
    // import('slick-carousel').then(function() {
        $sliders.each(function() {
            $(this).slick({
                slidesToShow: 1,
                dots: true,
                arrows: true,
                centerMode: true,
                variableWidth: false,
                fade: true,
                infinite: true,
                cssEase: 'cubic-bezier(0.4, 0, 0.3, 1)',
                prevArrow: '<button type="button" class="slider__arrow arrow-prev" aria-label="Prev" title="Prev"><span class="arrow arrow-left" aria-hidden="true"></span></button>\n',
                nextArrow: '<button type="button" class="slider__arrow arrow-next" aria-label="Next" title="Next"><span class="arrow arrow-right"></span></button>\n',
                appendArrows: '.img-slider__wrapper .slider__arrows',
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            variableHeight: true
                        }
                    }
                ]
            });
        });
    // });
}
