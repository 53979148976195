"use strict";

import $ from 'jquery';
import {getPrefixedDataSet} from './lazyImg';
import * as lightgallery from 'lightgallery';
import * as video from 'video.js';
import * as lgVideo from 'lg-video';

const defaultOptions = {
    video: false,
    animateThumb: false,
    download: false,
    counter: false
};

const defaultSelectors = {
    base: '.js-lightbox',
    item: '.js-lightbox__item'
};

export function initInScope(selectors = defaultSelectors, options) {
    // return function ($scope = $('body')) {
        let $scope = $('body');
        return $scope.find(selectors.base).each(function () {
            let $lightbox = $(this);
            let $lightboxItems = $lightbox.find(selectors.item);

            let elementOptions = {
                ...defaultOptions,
                ...options,
                ...getPrefixedDataSet('lightbox', $lightbox)
            };

            if (elementOptions.video) {
                /* the following imports are only needed if there are videos in your lightGallery*/
                /* IMPORTANT: Do not forget to add 'lg-video-object lg-html5' to video-elements*/
                /* IMPORTANT: The controls attribute must be set on the <video>*/
                /* IMPORTANT: class "js-video-js" is not necessary for videos in the lightgallery (own molecule) */

                /* because lg-video needs a global instance of videojs */
                window.videojs = videojs.default;

                import('lg-video');
            }

            $lightbox.lightGallery({
                ...elementOptions,
                selector: selectors.item,
                videojs: elementOptions.video,
            });

            //prevent isClickable
            $lightboxItems.on('click', function (evt) {
                evt.stopImmediatePropagation();
            });
        });
    // }
}