"use strict";

import $ from 'jquery';
import {loadImg} from "./lazyImg";
import * as carousel from "slick-carousel";

const defaultSelectors = {
    base: '.js-lazy-img',
    backgroundModifier: '.js-lazy-img--bg'
};

const defaultOptions = {
    offset: window.innerHeight / 2,
    preventNative: false
};


export function initInScope ($scope = $('body')) {
    let $sliders = $scope.find('.js-slickslider');
    // import('slick-carousel').then(function() {
        $sliders.each(function() {
            $(this).slick({
                dots: true, /*should always be true; hide with css (accessibility)*/
                slidesToShow: 1,
                cssEase: 'cubic-bezier(0.4, 0, 0.3, 1)',
                speed:900,
                arrows:false,
                swipeToSlide: true,
                infinite: false
            });
        });
    // });
}

export function preloadSlide($slider, slick, slideIndex) {
    let slideToPreload = $slider.find(`[data-slick-index='${slideIndex}']`);

    if (slideToPreload) {
        let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
        if ($imgToPreload && $imgToPreload.length) {
            loadImg($imgToPreload);
        }
    }
}