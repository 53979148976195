import 'popper.js';
// import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

/* ----------------------------------------------------------- */
/* --------------------- HELPER SCRIPTS ---------------------- */
/* ----------------------------------------------------------- */
import * as goToLink from './scripts/goToLink';
goToLink.init();

import * as isInViewport from "./scripts/isInViewport";
isInViewport.initInScope();

import * as smoothScroll from "./scripts/smoothScroll";
let selectors = {
    link: '.js-smoothscroll__link',
    dataOffset: 'smoothscroll-offset'
};
// setting navi height as offset to prevent it from cutting off content
let options = {
    offset: document.getElementById('main-navbar').getBoundingClientRect().height
};
smoothScroll.init(options, selectors);
smoothScroll.initInScope();

import * as slickslider from "./scripts/slickslider";
slickslider.initInScope();

import * as lazyImg from "./scripts/lazyImg";
lazyImg.initInScope();



/* ----------------------------------------------------------- */
/* ------------------------ COMPONENTS ------------------------*/
/* ----------------------------------------------------------- */
import * as navbar from './scripts/navbar';
navbar.init();

import * as thumbSlider from "./scripts/thumbSlider";
thumbSlider.initInScope();

import * as imgSlider from "./scripts/imgSlider";
imgSlider.initInScope();

import * as lightgallery from "./scripts/lightgallery";
lightgallery.initInScope();