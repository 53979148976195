"use strict";

import $ from 'jquery';
import {preloadSlide} from "./slickslider";
import {onEnterViewPort} from "./isInViewport";

let sliderId = 0;

export function initInScope($scope = $('body')) {
    let $slider = $scope.find('.js-thumb-slider');

    // import('slick-carousel').then(function() {
        $slider.each(function () {
            let $container = $(this);
            let $mainSlider = $container.find('.js-thumb-slider__main');
            let $thumbSlider = $container.find('.js-thumb-slider__thumbs');
            let id = sliderId++;

            $mainSlider.attr('id', 'main-slider__main-' + id);
            $thumbSlider.attr('id', 'thumb-slider__thumb-' + id);

            onEnterViewPort($container, function () {
                preloadSlide($mainSlider, $mainSlider.slick('getSlick'), (matchMedia('(max-width: 767px)').matches ? 2 : 1));
            });

            $mainSlider.on('afterChange', function(event, slick, currentSlide) {
                preloadSlide($mainSlider, slick, currentSlide + (matchMedia('(max-width: 767px)').matches ? 2 : 1));
                preloadSlide($mainSlider, $mainSlider.slick('getSlick'), currentSlide -1);
            }).slick({
                asNavFor: '#thumb-slider__thumb-' + id,
                dots: true,
                arrows: true,
                prevArrow: '<button type="button" class="slider__arrow arrow-prev" aria-label="Prev" title="Prev"><span class="arrow arrow-left" aria-hidden="true"></span></button>\n',
                nextArrow: '<button type="button" class="slider__arrow arrow-next" aria-label="Next" title="Next"><span class="arrow arrow-right"></span></button>\n',
                appendArrows: '.thumb-slider .slider__arrows',
                infinite: true,
                fade: true,
                centerMode: true,
                cssEase: 'cubic-bezier(0.4, 0, 0.3, 1)',
                speed: 600,
                focusOnSelect: true,
                responsive: [{
                    breakpoint: 767,
                    settings: {
                        asNavFor: null,
                        slidesToShow: 1
                    }
                }]
            });

            if(matchMedia('(min-width: 768px)').matches) {
                if($thumbSlider && $thumbSlider.length) {
                    onEnterViewPort($thumbSlider, function () {
                        preloadSlide($thumbSlider, $thumbSlider.slick('getSlick'), -1);
                        preloadSlide($thumbSlider, $thumbSlider.slick('getSlick'), (matchMedia('(max-width: 767px)').matches ? 2 : 4));
                    });
                    $thumbSlider.slick({
                        dots: true,
                        arrows: false,
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        variableWidth: true,
                        swipeToSlide: true,
                        centerMode: false,
                        asNavFor: '#main-slider__main-' + id,
                        focusOnSelect: true,
                        centerPadding: '0px'
                    }).on('afterChange', function(event, slick, currentSlide) {
                        preloadSlide($thumbSlider, slick, currentSlide + 3);
                    })
                }
            }
        });
    // });
}

