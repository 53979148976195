"use strict";

import $ from 'jquery';
import {onEnterViewPort} from "./isInViewport";
import * as picture from 'picturefill';

const defaultSelectors = {
    base: '.js-lazy-img',
    backgroundModifier: '.js-lazy-img--bg'
};

const defaultOptions = {
    offset: window.innerHeight / 2,
    preventNative: false
};

// export const initInScope = createInitInScope();

export function initInScope(selectors = defaultSelectors, options = defaultOptions) {
    // return function ($scope = $('body')) {
    let $scope = $('body');
    let $images = $scope.find(selectors.base);

    $images = $images.map((_, el) => {
        return $(el).closest('picture').length > 0 ? $(el).closest('picture') : $(el);
    });

    if ('loading' in HTMLImageElement.prototype && !options.preventNative) {
        $images.each((_, el) => {
            const $image = $(el);

            if ($image.is(selectors.backgroundModifier)) {
                onEnterViewPort($image, function (img) {
                    loadImg($(img), selectors, options);
                }, options);
            } else {
                switchImage($image, true)
            }

        })
    } else {
        $images.each((_, el) => {
            const $image = $(el);

            let elementOptions = {
                ...defaultOptions,
                ...options,
                ...getPrefixedDataSet('lazy-img', $image)
            };

            onEnterViewPort($image, function (img) {
                loadImg($(img), selectors, elementOptions);
            }, elementOptions);
        });
    }

    return $images;
    // };
}

export function loadImg($img, selectors = {}) {
    selectors = {...defaultSelectors, ...selectors};

    if ($img.is(selectors.backgroundModifier)) {
        // background image
        let url = getPrefixedDataSet('lazy-img', $img).background;
        if (url) {
            $img.css('background-image', 'url(' + url + ')');
        }
    } else {
        // usual image
        switchImage($img);

        if (!window.HTMLPictureElement) {
            // import('picturefill').then(function () {
                picturefill({
                    reevaluate: true
                });
            // });
        }
    }
}


function switchImage($img, addLazyLoading = false) {
    if ($img.is('picture')) {
        $img.find('source, img').each(function () {
            if (addLazyLoading) {
                $img.find('img').attr('loading', 'lazy')
            }
            if ($(this).data('srcset')) {
                $(this).attr('srcset', $(this).data('srcset'));
            }

            if ($(this).data('src')) {
                $(this).attr('src', $(this).data('src'));
            }
        });
    } else {
        if (addLazyLoading) {
            $img.attr('loading', 'lazy');
        }

        if ($img.data('srcset')) {
            $img.attr('srcset', $img.data('srcset'));
        }

        if ($img.data('src')) {
            $img.attr('src', $img.data('src'));
        }
    }

}

export function getPrefixedDataSet(prefix, $domNode) {
    prefix = lowerCaseFirstLetter(prefix.split('-').map(upperCaseFirstLetter).join(''));

    return Object.entries($domNode.data() || {})
        .filter(([key]) => key.startsWith(prefix))
        .map(([key, value]) => ([lowerCaseFirstLetter(key.replace(prefix, '')), value]))
        .reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
}

function lowerCaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}
function upperCaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
