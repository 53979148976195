let navi = document.getElementById('main-navbar'),
    naviHeight = navi.getBoundingClientRect().height,
    scrollPos,
    toggler = document.getElementById('navbarToggler');

export function init() {
    window.addEventListener('scroll', function() {
        scrollPos = window.scrollY;
        if (scrollPos >= naviHeight) {
            navi.classList.add('nav-solid');
        } else {
            navi.classList.remove('nav-solid');
        }
    });

    toggler.addEventListener('click', function() {
        navi.classList.toggle('open');
    });
}
